<template>
  <main class="w-full">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop my-20 m-auto w-full">
      <div class="relative w-full bg-gray1/80 rounded-2xl py-16">
        <form class="flex flex-col justify-center items-center">
          <p class="text-center text-3xl md:text-5xl font-black mb-2 Cabinet_font">Join our Newsletter</p>
          <p class=" lg:px-[27%] text-center text-sm md:text-base">Enter your email to join the mailing list and  get early access to updates on Reback</p>
          <div class="grid grid-cols-2 lg:grid-cols-3 items-center justify-start mt-8 gap-2 md:gap-5 lg:gap-0 ml-2 md:ml-0">
            <input class="text-secondary h-12 md:h-16 border-secondary border border-solid px-4 rounded-lg mr-2 md:mr-4" v-model="firstName" type="text" placeholder="First name" />
            <input  class="text-secondary h-12 md:h-16 border-secondary border border-solid px-4 rounded-lg mr-4" v-model="email" type="email" placeholder="Email address" />
            <button class="col-span-2 lg:col-span-1 mt-3 lg:mt-0 w-2/3 lg:w-full m-auto text-sm md:text-base lg:text-base py-3 md:py-5 px-20 bg-secondary text-gray1 rounded-full">Subscribe</button>
          </div>
        </form>
        <img :src="colouredcurve" class="absolute bottom-0 right-0" />
        <img :src="colourlesscurve" class="absolute top-0 left-0" />
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'news-letter',
    data () {
      return {
        colouredcurve: require('@/assets/images/colouredcurve.svg'),
        colourlesscurve: require('@/assets/images/colourlesscurve.svg'),
        firstName: '',
        email: ''
      }
    }
  }
</script>