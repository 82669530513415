<template>
  <main id="about" class="py-24 bg-gray3 w-full">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop w-full m-auto flex flex-col md:flex-row items-start gap-16">
      <div class="flex-1">
        <div class="flex items-center bg-gray2 shadow-md p-6 md:p-4 lg:p-6 rounded-xl mb-6">
          <p class="text-5xl mr-8 ml-2 text-blue-shade font-semibold">1</p>
          <div class="pr-4">
            <p class="text-xl font-semibold mb-2">Save Time</p>
            <p class="text-darker-shade text-sm lg:text-lg">Say goodbye to manual invoicing processes that take too much of your time.</p>
          </div>
        </div>
        <div class="flex items-center bg-gray2 shadow-md p-6 md:p-4 lg:p-6 rounded-xl mb-6">
          <p class="text-5xl mr-8 ml-2 text-blue-shade font-semibold">2</p>
          <div class="pr-4">
            <p class="text-xl font-semibold mb-2">Get Paid</p>
            <p class="text-darker-shade text-sm lg:text-lg">Generate reports and receive payments securely and directly to your bank account.</p>
          </div>
        </div>
        <div class="flex items-center bg-gray2 shadow-md p-6 md:p-4 lg:p-6 rounded-xl">
          <p class="text-5xl mr-8 ml-2 text-blue-shade font-semibold">3</p>
          <div class="pr-4">
            <p class="text-xl font-semibold mb-2">Look Professional</p>
            <p class="text-darker-shade text-sm lg:text-lg">Quickly create professional invoices and estimates wherever you are.</p>
          </div>
        </div>
      </div>
      <div class="flex-1 w-full lg:w-5/6">
        <p class="text-4xl lg:text-5xl font-bold mb-5 leading-snug md:leading-relaxed Cabinet_font">The easiest invoicing software you’ll ever use</p>
        <p class="text-darker-shade leading-loose text-sm md:text-base lg:text-lg">Reback streamlines your business, helps you connect with your team and clients to generate invoices improve sales, build trust and spur your business</p>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5YbA5FGT7PiBFY237UOGeTPegvOS5t0b4r9rxb5L_1Kbj5w/viewform" target="_blank"><button class="mt-16 md:ml-[12%] text-sm md:text-base lg:text-lg lg:text-xl py-5 px-10 bg-primary text-gray1 rounded-full">Join our waiting list</button></a>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'Features-page'
  }
</script>

<style></style>
