<template>
  <main class="w-full" id="faq">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop m-auto">
      <p class="text-center text-3xl md:text-5xl font-black mb-6 Cabinet_font">Frequently Asked Questions</p>
      <p class="text-center text-sm md:text-lg text-dark-shade">Here are some answers to  some questions  you need to  know about the product and billing</p>
      <div class="my-16">
        <transition name="accordion-fade-slide" mode="out-in">
          <div v-if="showAccordion" class="max-w-faq m-auto">
            <div
              class="border-b border-solid border-gray4 transition-all duration-500"
              v-for="(item, i) in myItems"
              :key="`accordion-item-${i}`"
            >
              <div :class="generateQuestionClasses(i)" @click="makeActive(i)">
                <p class="text-base md:text-xl font-semibold" v-html="item.title"></p>
                <div class="pb-3 md:pb-5 pt-0 md:pt-2 px-3 md:px-5 bg-gray4 rounded-full">
                  <button :class="generateButtonClasses(i)"></button>
                </div>
              </div>
                <div>
                  <div v-if="i === activeQuestionIndex">
                      <div class="text-sm md:text-base text-left text-dark-shade pt-0 pl-0 p-5">
                        <slot v-bind="item">
                          <div v-html="item.value"></div>
                        </slot>
                      </div>
                  </div>
                </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'F-A-Q',
    data () {
      return {
        activeTab: '',
        activeQuestionIndex: null,
        showAccordion: true,
        myItems: [
          {
            title: "How does Reback Work?",
            value:
              "Generating invoices with the link of a product or service posted on social media, receiving payment, tracking payment, accessing escrow services amd creating virtual cards are all possible on this platform, to generate an invoice, the SME, MSME, Social vendor or freelancer will need to create an account"
          },
          {
            title: "Why do I need to complete Reback KYC?",
            value:
              "KYC is a set of processes and procedures designed to identify and verify the identity of a customer or a potential customer to prevent fraud and other criminal actvities. The Reback KYC helps us protect you, our customers, business to ensure transactions are conducted in a secure manner and complaint manner"
          },
          {
            title: "How do I use the escrow service?",
            value: "You can do this by selecting escrow in the payment method when creating or updating your invoice"
          },
          {
            title: "Can I upgrade myself?",
            value: "Yes! You can upgrade yourself by logging into your account. Go to the profile section, click on upgrade accounnt and update all required fialds with text and requested documents or photos."
          },
          {
            title: "What is the accepted payment method?",
            value: "We will help you accept payment fromm bank and prepaid cards, Bank transfer and crypto currencies."
          },
          {
            title: "How do I dispute an invoice?",
            value: "You can reach out to us via the support page, We will sort out your queries or concerns and ensure you have a delightful experience"
          },
          {
            title: "How do I issue recurring invoice",
            value: "You can issue a recurring invoice by selecting that the invoice being created is a recurring invoice during invoice creation"
          },
          {
            title: "Can I see my invoice and payment history?",
            value: "Yes! On the dashboard tab of your account you can see the invoices and payment hiistory via the transactions and invoice overview"
          },
          {
            title: "How do I update my billing information?",
            value: "You can update billing information in the update invoice data section of your account"
          },
          {
            title: "Is there a threshold for virtual cards payment?",
            value: "Reback virtual card has a daily spend limit of $5000 and maximum monthly spend limit of $10000"
          },
        ]
      }
    },
    methods: {
      makeActive (itemIndex) {
        this.activeQuestionIndex = this.activeQuestionIndex === itemIndex ? null : itemIndex
        this.$emit('itemSelect', { itemIndex })
      },
      generateButtonClasses (buttonIndex) {
        return [
          'relative w-[10px] md:w-[16px] h-[10px] md:h-[16px] transition-all duration-300 origin-center cursor-pointer before:content-[""] before:absolute before:left-0 before:w-full before:h-[2px] before:transition-all before:duration-300 before:bg-black before:rotate-90 after:content-[""] after:absolute after:left-0 after:w-full after:h-[2px] after:transition-all after:duration-300 after:bg-black',
          this.activeQuestionIndex === buttonIndex
            ? 'before:hidden before:bg-black after:bg-black'
            : null
        ]
      },
      generateQuestionClasses (questionIndex) {
        return [
          'flex justify-between items-center pr-4 pl-0 py-6 cursor-pointer text-dark-shade hover:text-black',
          this.activeQuestionIndex === questionIndex
            ? 'text-black'
            : null
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>
  .accordion-fade-slide {
    &-enter-active,
    &-leave-active {
      transition: all 0.3s;
    }
    &-enter {
      transform: translateY(-25px);
      opacity: 0;
    }
    &-leave-to {
      transform: translateY(25px);
      opacity: 0;
    }
  }
</style>