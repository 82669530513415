<template>
  <main id="support" class="w-full hero_background py-16 md:py-20 lg:py-32">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop m-auto text-secondary">
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 items-start justify-between w-full gap-10 md:gap-8 lg:gap-0">
        <img
          :src="logo"
          class="self-start lg:self-center"
        />
        <div>
          <p class="text-lg font-semibold mb-5 md:mb-10">REBACK SERVICES</p>
          <p class="mb-5 text-sm md:text-base">Invoices</p>
          <p class="mb-5 text-sm md:text-base">Escrow Services</p>
          <p class="mb-5 text-sm md:text-base">Payments</p>
          <p class="mb-5 text-sm md:text-base">Reports</p>
        </div>
        <div>
          <p class="text-lg font-semibold mb-5 md:mb-10">INFORMATION</p>
          <p class="mb-5 text-sm md:text-base">Terms and Condition</p>
          <p class="mb-5 text-sm md:text-base">FAQs</p>
          <p class="mb-5 text-sm md:text-base">Cookies Policies</p>
          <p class="mb-5 text-sm md:text-base">Security</p>
          <p class="mb-5 text-sm md:text-base">Customer Feedback Survey</p>
        </div>
        <div>
          <p class="text-lg font-semibold mb-5 md:mb-10">ABOUT US</p>
          <p class="mb-5 text-sm md:text-base">Contact Us</p>
        </div>
      </div>
      <div class="mt-12">
        <div class="h-[1.3px] w-full bg-secondary"></div>
        <div class="py-6 flex flex-col md:flex-row justify-between">
          <div class="flex items-center">
            <img :src="flag" />
            <p class="text-sm md:text-base ml-2 mr-4">English</p>
            <img :src="arrowdown" />
          </div>
          <div class="text-sm md:text-base mt-5 md:mt-0 flex justify-between md:w-3/5 lg:w-2/5">
            <a href="https://www.linkedin.com/company/reback-finance/" target="_blank">LinkedIn</a>
            <a href="#">Facebook</a>
            <a href="#">Twitter</a>
            <a href="https://instagram.com/rebackfi_hq?igshid=YmMyMTA2M2Y=" target="_blank">Instagram</a>
          </div>
        </div>
        <div class="h-[1.7px] w-full bg-secondary"></div>
      </div>
      <div class="mt-2 flex">
        <img :src="copyright" class="mr-2" /><p class="text-sm md:text-base">2023 RebackFinance. All rights Reserved.</p>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: "Footer-section",
    data () {
      return {
        logo: require('@/assets/images/logo.svg'),
        arrowdown: require('@/assets/images/footer/arrowdown.svg'),
        copyright: require('@/assets/images/footer/copyright.svg'),
        flag: require('@/assets/images/footer/flag.svg'),
      }
    }
  }
</script>

<style></style>