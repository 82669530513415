<template>
  <main class="relative overflow-hidden">
    <Navbar />
    <Herosection />
    <Whychooseus />
    <Features />
    <Whocanuse />
    <FAQ />
    <Newsletter />
    <Footer />
    <Sidebutton />
    <Feedbackbutton />
  </main>
</template>

<script>
import { Herosection, Navbar, Whychooseus, Features, Whocanuse, FAQ, Newsletter, Footer, Sidebutton, Feedbackbutton } from '@/components';

export default {
  name: 'App',
  components: {
    Herosection,
    Navbar,
    Whychooseus,
    Features,
    Whocanuse,
    FAQ,
    Newsletter,
    Footer,
    Sidebutton,
    Feedbackbutton
}
}
</script>

<style>
@font-face {
  font-family: "Cabinet Grotesk";
  src: url(@/assets/CabinetGrotesk-Black.ttf);
  font-weight: 900;
}
@font-face {
  font-family: "Cabinet Grotesk";
  src: url(@/assets/CabinetGrotesk-Bold.ttf);
  font-weight: 700;
}
.Cabinet_font {
  font-family: 'Cabinet Grotesk';
}
</style>
