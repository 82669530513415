<template>
  <main class="w-full">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop py-24 m-auto w-full">
      <p class="text-center text-3xl md:text-5xl font-black mb-20 Cabinet_font">Who can use Reback?</p>
      <div class="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 md:gap-10">
        <div class="text-center bg-gray2 flex flex-col items-center justify-center px-8 py-12 rounded-xl shadow-md shadow-secondary-200">
          <img :src="image1" />
          <p class="text-base md:text-xl font-bold mt-5">MSMEs</p>
        </div>
        <div class="text-center bg-gray2 flex flex-col items-center justify-center px-8 py-12 rounded-xl shadow-md shadow-secondary-200">
          <img :src="image2" />
          <p class="text-base md:text-xl font-bold mt-5">SMEs</p>
        </div>
        <div class="text-center bg-gray2 flex flex-col items-center justify-center px-8 py-12 rounded-xl shadow-md shadow-secondary-200">
          <img :src="image3" />
          <p class="text-base md:text-xl font-bold mt-5">Social Media Vendors</p>
        </div>
        <div class="text-center bg-gray2 flex flex-col items-center justify-center px-8 py-12 rounded-xl shadow-md shadow-secondary-200">
          <img :src="image4" />
          <p class="text-base md:text-xl font-bold mt-5">Freelancers</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'Who-can-use',
    data () {
      return {
        image1: require('@/assets/images/whocanuse/1.svg'),
        image2: require('@/assets/images/whocanuse/2.svg'),
        image3: require('@/assets/images/whocanuse/3.svg'),
        image4: require('@/assets/images/whocanuse/4.svg')
      }
    }
  }
</script>

<style></style>