<template>
  <main class="max-h-auto lg:max-h-hero lg:h-hero h-full w-full pt-32 pb-40 lg:py-32 hero_background">
    <div class="px-4 md:px-16 lg:px-0 max-w-desktop h-full relative m-auto ">
      <div class="flex flex-col lg:flex-row w-full">
        <div class="w-full lg:w-4/6 -mt-5 md:mt-0 lg:mt-36 md:pr-32 lg:pr-32">
          <p class="text-4xl md:text-hero font-black leading-relaxed md:leading-snug Cabinet_font">Invoicing, Payments and Escrow for small businesses</p>
          <p class="text-base md:text-base leading-loose md:leading-loose mt-4 md:mt-0 lg:pr-6">Say hello to Reback, Streamline your process with real-time tracking, secure online payments and built-in escrow.</p>
          <a href="https://docs.google.com/forms/d/e/1FAIpQLSc5YbA5FGT7PiBFY237UOGeTPegvOS5t0b4r9rxb5L_1Kbj5w/viewform"><button class="text-sm md:text-base lg:text-base py-5 px-10 bg-primary text-gray1 rounded-full mt-9">Explore our Platform <img :src="arrow_right" class="inline ml-4"/></button></a>
        </div>
        <img class="hidden lg:inline-block ml-5 self-start mt-[19%]" :src="overlapping_boxes" />
        <img class="mt-12 lg:mt-0 lg:inline-block lg:-ml-20" :src="create_invoice" />
      </div>
      <img :src="guy_img" class="hidden lg:inline-block absolute -right-[5%] top-[40%] z-10" />
      <img :src="recieve_payment" class="w-64 md:w-[450px] h-[500px] lg:h-auto lg:w-auto lg:inline-block absolute right-1 lg:-right-[9.5%] -bottom-56 md:-bottom-36 lg:-bottom-16" />
      <img :src="box" class="hidden lg:inline-block absolute -bottom-20 right-[58%]" />
      <img :src="smallbox" class="lg:inline-block absolute right-[57%] md:right-[50%] lg:right-[25%] -bottom-[145px] lg:-bottom-20 -z-10" />
      <img :src="smallerbox" class="hidden lg:inline-block absolute left-[37%] bottom-[32.5%]" />
      <img :src="smallestbox" class="lg:inline-block absolute right-[74%] md:right-[58%] lg:right-[30%] -bottom-20 lg:-bottom-4" />
      <img :src="spiralline" class="hidden lg:inline-block absolute right-[28.5%] bottom-36" />
      <img :src="transaction" class="hidden lg:inline-block absolute -bottom-16 right-[31%]" />
      <img :src="ladymockup" class="hidden lg:inline-block absolute -right-[9.5%] top-4" />
      <button class="hidden lg:inline-block absolute -right-[7%] top-4 text-lg lg:text-xl py-3 px-7 bg-primary text-gray1 rounded-full mt-9">Paid</button>
    </div>
  </main>
</template>

<script>
export default {
  name: 'Hero-section',
  data () {
    return {
      arrow_right: require('@/assets/images/arrow-right.svg'),
      overlapping_boxes: require('@/assets/images/overlappingboxes.svg'),
      create_invoice: require('@/assets/images/createinvoice.svg'),
      guy_img: require('@/assets/images/guyimg.svg'),
      recieve_payment: require('@/assets/images/recievepayment.svg'),
      box: require('@/assets/images/box.svg'),
      smallbox: require('@/assets/images/smallbox.svg'),
      smallerbox: require('@/assets/images/smallerbox.svg'),
      smallestbox: require('@/assets/images/smallestbox.svg'),
      spiralline: require('@/assets/images/spiralline.svg'),
      transaction: require('@/assets/images/transaction.svg'),
      ladymockup: require('@/assets/images/ladymockup.svg')
    }
  }
}
</script>

<style>
.hero_background {
  background: linear-gradient(252.3deg, #AE9DD1 0.35%, rgba(244, 209, 241, 0.64878) 50.18%, rgba(165, 177, 180, 0) 100%);
}
</style>
