<template>
  <main class="absolute bottom-[1%] sm:bottom-[1.8%] -right-[21vw] sm:-right-[14vw] md:-right-[10vw] lg:-right-[8.7vw] xl:-right-[5.5vw] 2xl:-right-[4.5vw]">
    <div class="-rotate-90">
      <button class="text-white px-12 py-4 rounded-t-2xl bg-primary font-[800] text-xl">FEEDBACK</button>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'Feedback-button'
  }
</script>