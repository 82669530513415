<template>
  <main id="services" class="w-full">
    <div class="px-4 md:px-16 lg:px-0 py-24 max-w-desktop m-auto text-dark-shade">
      <p class="text-center text-3xl md:text-5xl font-black Cabinet_font">Why Choose Us?</p>
      <div class="mt-10 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-10 lg:gap-16">
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="invoicing" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-2 md:mb-4">Easy Invoicing</p>
          <p class="mb-4 text-sm md:text-base">Set up invoices for your business with a consistent payment schedule.</p>
        </div>
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="paymentoption" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-4">Multiple Payment Options</p>
          <p class="mb-4 text-sm md:text-base">Our platform integrates with various secure payments gateways.</p>
        </div>
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="escrowservice" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-4">Escrow Services</p>
          <p class="mb-4 text-sm md:text-base">Reduce the risk of fraud by using a trusted third-party to disburse funds.</p>
        </div>
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="escrowservice" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-4">Buyer & Seller Protection</p>
          <p class="mb-4 text-sm md:text-base">Integrating a cutting-edge encryption to ensure the safety of your transaction</p>
        </div>
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="sales" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-4">Increased Sales</p>
          <p class="mb-4 text-sm md:text-base">Increase your sales with a digital and sustainable platform.</p>
        </div>
        <div class="bg-gray1/80 shadow-md rounded-2xl p-4 md:p-6">
          <img :src="report" class="bg-secondary-200 rounded-full py-5 px-6 mb-4 md:mb-8" />
          <p class="text-xl md:text-2xl font-medium mb-4">Insightful Reports</p>
          <p class="mb-4 text-sm md:text-base">Generate reports to track the success of your invoicing and sales processes.</p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
  export default {
    name: 'Why-choose-us',
    data () {
      return {
        invoicing: require('@/assets/images/whychooseus/invoicing.svg'),
        paymentoption: require('@/assets/images/whychooseus/paymentoption.svg'),
        escrowservice: require('@/assets/images/whychooseus/escrowservice.svg'),
        protection: require('@/assets/images/whychooseus/protection.svg'),
        sales: require('@/assets/images/whychooseus/sales.svg'),
        report: require('@/assets/images/whychooseus/report.svg'),
      }
    }
  }
</script>

<style></style>
